<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.pendingResults') }}</h5>
                <Toast />
                <DataTable :value="items" @page="onPage($event)" :paginator="true" :rows="perPage" :loading="loading" class="p-mt-3" ref="datatable" responsiveLayout="scroll" :paginatorBottom="true">
                    <template #header>
                        <div class="p-grid">
                            <div class="p-col-12 p-md-2">
                                <Calendar ref="startDateCalendar" style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="startDateModel"></Calendar>
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="endDateModel"></Calendar>
                            </div>
                            <div class="p-col-12 p-md-2 p-md-offset-6 p-text-right"><Button :label="$t('buttons.showResults')" class="p-button-raised p-button-success" @click="readItems()" /></div>
                        </div>
                    </template>
                    <Column
                        ><template #body="slotProps">
                            <span class="indexColumn">{{ (this.crtPage - 1) * this.perPage + slotProps.index + 1 }}</span>
                        </template>
                    </Column>
                    <Column field="fixtureId" :header="$t('sportsbets.fixtureId')" :sortable="true"></Column>
                    <Column field="offerNumber" :header="$t('sportsbets.offerNo')" :sortable="true"></Column>
                    <Column field="date" :header="$t('sportsbets.date')" :sortable="true">
                        <template #body="{ data }"> {{ formatCSDateAndTime(data.date) }} </template>
                    </Column>
                    <Column field="sport.name" :header="$t('sportsSettings.sport')" :sortable="true"></Column>
                    <Column field="category.name" :header="$t('sportsSettings.category')" :sortable="true"></Column>
                    <Column field="tournament.name" :header="$t('sportsSettings.tournament')" :sortable="true"></Column>

                    <Column field="name" :header="$t('sportsSettings.name')"> </Column>
                    <Column field="marketsCount" :header="$t('sportsSettings.marketsNo')" :sortable="true"> </Column>
                    <Column>
                        <template #body="{ data }">
                            <Button :label="$t('buttons.showTickets')" class="p-button-text p-button-plain p-button-info p-mr-2 p-mb-2" @click="goto('sportBetsTickets', { backRoute: this.crtRoute, redirectFixtureId: data.fixtureId })" />
                        </template>
                    </Column>
                    <Column :exportable="false" class="p-text-right">
                        <template #body="{ data }">
                            <Button :label="$t('buttons.tryGetResults')" class="p-button-info p-button-text p-mr-2" @click="getResults(data.fixtureId, data.name)" v-show="checkShowResultsLink(data.fixtureId)" />
                        </template>
                    </Column>
                    <Column :exportable="false" class="p-text-right">
                        <template #body="{ data }">
                            <Button
                                icon="pi pi-pencil"
                                class="p-button-rounded p-button-warning p-mr-2"
                                @click="goto('eventResults', { id: data.fixtureId, fixtureId: data.fixtureId, isPendingResults: true, fixturesActive: true, backRoute: crtRoute })"
                            />
                        </template>
                    </Column>
                </DataTable>
                <Dialog :header="$t('general.success')" v-model:visible="showOkAlert" :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true">
                    <p>
                        {{ validatedTickets }}
                    </p>
                    <template #footer>
                        <Button :label="$t('buttons.close')" @click="close" icon="pi pi-times" class="p-button-danger" />
                        <Button :label="$t('buttons.showTickets')" @click="redirectToTickets()" icon="pi pi-check" class="p-button-success" />
                    </template>
                </Dialog>
                <Dialog :header="$t('general.error')" v-model:visible="showErrorAlert" :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true">
                    <p>
                        {{ $t('alerts.generalError') }}
                    </p>
                    <template #footer>
                        <Button :label="$t('buttons.close')" @click="close" icon="pi pi-times" class="p-button-danger" />
                    </template>
                </Dialog>
            </div>
        </div>
    </div>
</template>
<script>
import { SportsApi } from '../../service/SportsApi';
import { uiSettings } from '../../settings/uiSettings';
import { store } from '../../store/index';
export default {
    name: 'LottoResults',
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            items: [],
            SportsApi: new SportsApi(),
            crtPage: 1,
            startDateModel: null,
            endDateModel: null,
            startDateString: '',
            endDateString: '',
            perPage: uiSettings.tableRows,
            loading: true,
            totalPages: 0,
            showOkAlert: false,
            showErrorAlert: false,
            validatedTickets: '',
            showResultsLink: [],
            crtRoute: 'pendingResults',
        };
    },
    computed: {
        storeFilterData() {
            return store.getters['filters/filter'](this.crtRoute);
        },
    },
    watch: {},
    mounted() {
        if (this.storeFilterData != undefined) {
            this.startDateModel = new Date(this.storeFilterData.data.startDate);
            this.endDateModel = new Date(this.storeFilterData.data.endDate);
        } else {
            let crtDate = new Date();
            let year = crtDate.getFullYear();
            let month = crtDate.getMonth() + 1;
            if (month <= 9) {
                month = '0' + month;
            }
            let day = crtDate.getDate();
            if (day <= 9) {
                day = '0' + day;
            }
            this.startDateString = year + '-' + month + '-' + day + 'T00:00';
            this.endDateString = year + '-' + month + '-' + day + 'T23:59';
            this.startDateModel = new Date(this.startDateString);
            this.endDateModel = new Date(this.endDateString);
        }
        this.readItems();
    },
    methods: {
        composeAndSaveFiltersToStore() {
            let filters = {
                startDate: this.startDateModel,
                endDate: this.endDateModel,
            };
            this.saveFiltersToStore(filters, this.crtRoute);
        },
        readItems() {
            this.loading = true;
            var startDate = this.formatApiDate(this.startDateModel);
            var endDate = this.formatApiDate(this.endDateModel);
            this.SportsApi.getPendingResults(startDate, endDate)
                .then((response) => {
                    this.composeAndSaveFiltersToStore();
                    this.items = response.data;
                    this.loading = false;
                    for (var i = 0; i < response.data.length; i++) {
                        var showResultsObj = {
                            fixtureId: response.data[i].fixtureId,
                            showLink: true,
                        };
                        this.showResultsLink.push(showResultsObj);
                    }
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        getResults(fixtureId, fixtureName) {
            this.SportsApi.tryGetResults(fixtureId)
                .then((response) => {
                    if (response.data == 'Ok') {
                        this.resultsMessage = this.$t('sportsbets.results.added', { fixtureName: fixtureName });
                        this.showToast('success');
                        this.hideResultsLink(fixtureId);
                    }
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    if (error.response.data == 'No results') {
                        this.resultsMessage = this.$t('sportsbets.results.noResults', { fixtureName: fixtureName });
                        this.showToast('error');
                    } else {
                        if (error.response.data == 'Postponed') {
                            this.resultsMessage = this.$t('sportsbets.results.postponed', { fixtureName: fixtureName });
                            this.showToast('info');
                        }
                    }
                });
        },
        checkShowResultsLink(fixtureId) {
            var checkObject = this.showResultsLink.find((f) => f.fixtureId == fixtureId);
            if (checkObject != undefined && checkObject.showLink == true) {
                return true;
            } else {
                return false;
            }
        },
        hideResultsLink(fixtureId) {
            var checkObject = this.showResultsLink.find((f) => f.fixtureId == fixtureId);
            if (checkObject != undefined) {
                checkObject.showLink = false;
            }
        },
        showToast(toastSeverity) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: this.resultsMessage, life: this.getToastLifeSeconds() });
        },
        onPage(event) {
            this.crtPage = event.page + 1;
        },
    },
};
</script>
